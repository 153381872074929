.icon-style {
    color: white;
    font-size: 2rem;
}

.icon-font {
    font-family: 'Gayathri', sans-serif;
    font-size: 16px;
    font-weight: 100;
    /* padding-bottom: 20px; */
}


@media (max-width:992px) {
    .icon-margin {
        padding-left: 5rem;
    }
}

@media (max-width:576px) {
    .icon-style {
        font-size: 12px;
    }

    .icon-margin {
        padding-left: 2rem;
    }
}

.icon-size {
    font-size: 22px;
}

.icon-link {
    color: #fff;
    text-decoration: none;
}