.floating-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 9999;
}

.btn-ws {
    display: inline-block;
}

.btn-ws img {
    width: 80px;
    height: 80px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.btn-ws:hover img {
    transform: scale(1.1);
}