.principal-section {
    width: 100%;
    height: 500px;
    position: relative;
    text-align: center;
    color: white;
}

@media (max-width: 600px) {
    .principal-section {
        width: 100%;
    }
}

.principalimg-h {
    height: 550px;
}

.principal-text {
    position: absolute;
    top: 320px;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: #32b4b8c2; */
    background-color: #32b4b843;
    backdrop-filter: blur(7px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    height: 180px;

    box-shadow: 0px 5px 18px 4px rgba(0, 0, 0, 0.307);
}

@media (max-width:690px) {
    .principal-text {
        top: 318px !important;
    }
}

@media (max-width:561px) {
    .principal-textfont {
        padding-left: 2.5rem;
    }

    .secondary-textfont {
        padding-left: 0rem;
    }
}

@media (max-width:561px) {
    .principal-textfont {
        top: 35%;
    }
}

.principal-textfont {
    font-family: 'Advent Pro', sans-serif;
    font-size: 3.25rem;
    color: white;
    line-height: 1em;
}

@media (max-width:690px) {
    .principal-textfont {
        text-align: start !important;
        padding-top: 0.5rem;
        padding-right: 2rem;
    }

    .h2-ps {
        padding-right: 2rem;
    }
}

@media (max-width:561px) {
    .principal-textfont {
        font-size: 3rem;
        text-align: start !important;
    }
}

.secondary-textfont {
    font-family: 'Advent Pro', sans-serif;
    font-size: 2rem;
    color: white;
}

@media (max-width:561px) {
    .secondary-textfont {
        font-size: 2rem;
        text-align: start !important;
    }
}

.image-full {
    display: block;
}

.image-mobile {
    display: none;
}

@media (max-width: 1000px) and (min-width: 200px) {
    .image-full {
        display: none;
    }

    .image-mobile {
        display: block;
    }
}

.width-mobile {
    width: 65vw;
}

@media (max-width:1300px) {
    .width-mobile {
        width: 90vw;
    }
}

@media (max-width:768px) {
    .width-mobile {
        width: 90vw;
    }
}

@media (max-width:576px) {

    .h1-ps {
        padding-left: 2.5rem;
    }

    .h2-ps {
        padding-left: 2.5rem;
    }

    .principal-text {
        height: 50% !important;
    }
}

@media (max-width:768px) {
    .h1-ps {
        padding-left: 2.5rem;
    }

    .h2-ps {
        padding-left: 2.5rem;
    }

    .principal-text {
        height: 350px !important;
    }
}