.product-img {
    border-radius: 50%;
    box-shadow: 5px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 250px;
    width: 250px;
}

@media (min-width: 992px) and (max-width: 1200px) { 
    .product-img {
        height: 200px;
        width: 200px;
    }
}
