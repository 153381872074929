.services-style {
    background-color: #FFFFFF;
    height: 34vh;
}

.services-font {
    color: #000000b4;
    font-family: 'Gayathri', sans-serif;
}

.service-icon {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #0097b2;
}

.service-title {
    font-size: 1.25rem;
    color: #0097b2;
}

@media (max-width:992px) {
    .vertical-service {
        height: 100% !important;
    }
}