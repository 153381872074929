.contact-bg {
    background-color: #131111;
}

.contact-title {
    font-family: 'Advent Pro', sans-serif;
    font-size: 3rem;
    margin-top: 1.5rem;
    color: #fff;
    text-align: center;
}

@media (max-width: 462px) {
    .contact-title {
        font-size: 2rem;
    }
}

.form-control {
    background-color: #131111 !important;
    color: #fff !important;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0px;
}

.form-control:focus {
    box-shadow: none !important;
}

.form-style {
    background-color: #131111;
    border: none;
    color: #fff;
    border: 0px;
    font-family: 'Gayathri', sans-serif;
}

input {
    background-color: #131111#131111;
}

.form-style::placeholder {
    color: white;
    opacity: 1;
}

.form-control::placeholder {
    color: white;
    opacity: 1;
}

.error-message {
    text-align: start !important;
    font-size: 12px;
    color: #ffffffc2;
    padding-top: 0.25rem;
    padding-left: 1.5rem;
    border-bottom: 0px;

}

.bb-0 {
    border-bottom: 0px;
}