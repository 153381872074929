.nav {
    height: 90px;
    background-color: #0097b2;
    font-family: 'Gayathri', sans-serif;
    z-index: 3000;
    /* margin-bottom: -10px; */
    border: none !important;
    --bs-navbar-toggler-icon-bg: url(https://cdn1.iconfinder.com/data/icons/office-292/128/_Hamburger_menu_options_-512.png);
    --bs-navbar-toggler-focus-width: none;
    --bs-navbar-toggler-border-color: none;
}

@media (max-width: 992px) {
    .nav {
        /* height: 100%; */
        text-align: center;
    }
}


.nav-logo {
    color: #ffffff;
    /* margin-bottom: -10px; */
    font-size: 2rem;
}

.size-logo {
    max-width: 75%;
}

@media (max-width: 992px) {
    .nav-logo {
        padding-left: 0px;
        /* max-width: 50% !important; */
    }

    .size-logo {
        max-width: 80%;
    }
}

.nav-logo:hover {
    color: #EDAC71;
}

.nav-links {
    font-size: 18px;
    color: #ffffff;
    margin-bottom: -10px;
}

@media (max-width: 992px) {
    .nav-links {
        margin-top: 10px;
    }
}

.nav-links:hover {
    color: #ffffff;
    font-weight: 500;
    text-decoration: underline;
}

.nav-space {
    flex-grow: 0;
    background-color: #0097b2;
}

@media (max-width: 992px) {
    .padding-resp {
        padding-right: 0px;
        padding-left: 0px;
        max-width: 100%;
    }
}


.navbar-toggler-icon {
    color: white !important;
    border-radius: none;
}

.navbar-nav {
    --bs-nav-link-hover-color: #32B4B8 !important;
}