.btn-principal {
    background-color: #32b4b8c2;
    text-decoration: none;
    border: none;
    border-radius: 40px;
    height: 3rem;
    --bs-btn-active-bg: #0097b2;
}

.btn:hover {
    background-color: #0097b2;
}