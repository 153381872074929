.title-style {
    font-family: 'Advent Pro', sans-serif;
    font-size: 3rem;
    margin-top: 1.5rem;
    padding-top: 2rem;
    color: #0097b2;
}

@media (max-width: 1200px) {
    .title-style {
        text-align: center !important;
    }
}

@media (max-width: 462px) {
    .title-style {
        padding-top: 2rem;
        text-align: center !important;
        font-size: 2rem;
    }
}