.img-us {
    width: 100%;
    height: 450px;
    position: relative;
    text-align: center;
    color: #000000b4;
}

.img-h {
    height: 450px !important;
}

.img-background {
    background-color: #ffffff9e;
    backdrop-filter: blur(7px);
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    height: 500px;
    width: 500px;
    border-radius: 0px;
    box-shadow: 2px -1px 10px 5px rgba(0, 0, 0, 0.307);

    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

@media (max-width: 1200px) {
    .img-background {
        background-color: #ffffffce;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 50vh;
        width: 80vw;
    }
}

@media (max-width: 532px) {
    .img-background {
        width: 480px;
    }
}

@media (max-width: 462px) {
    .img-background {
        background-color: #ffffffce;
        width: 400px;
        line-height: 1.3;
        height: 451px;
        /* top: 44%; */
    }
}

.text-us {
    text-align: justify;
    font-family: 'Gayathri', sans-serif;
}

.us-sectionstyle {
    font-family: 'Advent Pro', sans-serif;
    font-size: 3rem;
    color: #32b4b8c2;
}

@media (max-width: 462px) {
    .us-sectionstyle {
        font-size: 2rem;
    }
}

@media (max-width: 900px) {
    .img-background {
        /* width: 90vw; */
        height: 110%;
        background-color: #ffffffce;
    }
}

.textus-justify {
    text-align: justify;
}