.higher-style {
    background-color: #ffffff;
    text-decoration: none;
}

.higher-text {
    text-decoration: none;
    color: black;
    cursor: pointer !important;
    font-size: 14px;
}